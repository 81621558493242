<template>
    <v-container>
        <ComponentDeveloperAddSubscriptionHistory
            :id="this.$router.history.current.params.id"/>
    </v-container>
</template>
  
<script>
import ComponentDeveloperAddSubscriptionHistory from '../../components/developer/clean/ComponentDeveloperAddSubscriptionHistory.vue';
import { mapState } from 'vuex'
export default {
    components:{
    ComponentDeveloperAddSubscriptionHistory,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    //
    },
    methods: {
        fetch(){

        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){

        },
    }
}
</script>