<template>
    <v-container>
        <ASuccessFour :api="this.api"/>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader
            v-if="api.isLoading" ref="skeleton" type="table">

        </v-skeleton-loader>
        <v-row class="mt-3"
            v-if="!api.isLoading && form.name!=null">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <v-toolbar
                        class="secondary white--text text-h4 ma-4" flat>
                        <v-toolbar-title>
                            Subscription History
                        </v-toolbar-title>
                    </v-toolbar>
                    <!--BOC : Create a form here-->
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <div class="d-flex justify-start">
                                    <v-text-field
                                        dense 
                                        outlined
                                        v-model="form.name"
                                        disabled
                                        label="Company name">

                                    </v-text-field>
                                </div>
                                <!-- <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        dense   
                                        outlined
                                        label="Customer Service PIC"
                                        v-model="form.cs_pic">

                                    </v-text-field>
                                </div> -->
                                <v-spacer>

                                </v-spacer>
                                <div 
                                    class="d-flex justify-start mt-3 ">
                                    <h3>
                                        New Subscription Detail
                                    </h3>
                                </div>
                                <div
                                    class="d-flex justify-start mb-5 grey--text">
                                    <small>
                                        **For year 1 subscriptions
                                    </small>
                                </div>
                                <div
                                    class="">
                                    <ComponentDateModal
                                        label="Payment Date"
                                        :data="form.year_1_payment_date"
                                        @updateDateData="updateDataPaymentDate"
                                        />

                                </div>
                                <div >
                                    <v-row>
                                        <v-col>
                                            <ComponentDateModal
                                            label="Subscription start date"
                                            :data="form.year_1_subscription_start_date"
                                            @updateDateData="(data)=>{
                                                this.form.year_1_subscription_start_date = data;
                                            }"/>
                                        </v-col> 
                                        <v-col>
                                            <ComponentDateModal
                                                label="Subscription end date"
                                                :data="form.year_1_subscription_end_date"
                                                @updateDateData="(data)=>{
                                                    this.form.year_1_subscription_end_date = data;
                                                }"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        label="Salesperson"
                                        v-model="form.year_1_pic_salesperson"
                                        dense 
                                        outlined
                                        >
                                    </v-text-field>
                                </div>
                                <v-spacer>

                                </v-spacer>
                                <div 
                                    class="d-flex justify-start mt-3 ">
                                    <h3>
                                        Renewal Year 2 
                                    </h3>
                                </div>
                                <div
                                    class="d-flex justify-start mb-5 grey--text">
                                    <small>
                                        **For year 2 subscriptions
                                    </small>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-autocomplete
                                        label="Renewal Status"
                                        dense 
                                        outlined
                                        v-model="form.year_2_subscription_status"
                                        :items="optionRenewedStatus"
                                        >

                                    </v-autocomplete>
                                </div>
                                <div>
                                    <ComponentDateModal
                                        label="Payment Date"
                                        :data="form.year_2_payment_date"
                                        @updateDateData="(data)=> {
                                            this.form.year_2_payment_date = data;
                                        }"
                                    />
                                </div>
                                <div>
                                    <v-row>
                                        <v-col>
                                            <ComponentDateModal
                                            label="Subscription start date"
                                            :data="form.year_2_subscription_start_date"
                                            @updateDateData="(data)=>{
                                                this.form.year_2_subscription_start_date = data;
                                            }"
                                            />

                                        </v-col>
                                        <v-col>
                                            <ComponentDateModal
                                                label="Subscription end date"
                                                :data="form.year_2_subscription_end_date"
                                                @updateDateData="(data)=>{
                                                    this.form.year_2_subscription_end_date = data;
                                                }"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        label="Salesperson"
                                        dense 
                                        outlined 
                                        v-model="form.year_2_pic_salesperson">
                                    </v-text-field>
                                </div>
                            </v-col>
                        </v-row>
                        <v-spacer>

                        </v-spacer>
                        <div 
                            class="d-flex justify-start mt-3 ">
                            <h3>
                                Renewal Year 3 
                            </h3>
                        </div>
                        <div
                            class="d-flex justify-start mb-5 grey--text">
                            <small>
                                **For year 3 subscriptions
                            </small>
                        </div>
                        <div>
                            <v-autocomplete
                                label="Renewal Status"
                                dense 
                                outlined
                                v-model="form.year_3_subscription_status"
                                :items="optionRenewedStatus"
                                >
                            </v-autocomplete>
                        </div>
                        <div>
                            <ComponentDateModal
                                label="Payment date"
                                :data="form.year_3_payment_date"
                                @updateDateData="(data)=>{
                                    this.form.year_3_payment_date = data;
                                }"
                            />
                        </div>
                        <div>
                            <v-row>
                                <v-col>

                                    <ComponentDateModal
                                        label="Subscription start date"
                                        :data="form.year_3_subscription_start_date"
                                        @updateDateData="(data)=>{
                                            this.form.year_3_subscription_start_date = data;
                                        }"
                                    />
                                </v-col>
                                <v-col>
                                    <ComponentDateModal
                                        label="Subscription end date"
                                        :data="form.year_3_subscription_end_date"
                                        @updateDateData="(data)=>{
                                            this.form.year_3_subscription_end_date = data;
                                        }"/>
                                </v-col>
                            </v-row>
                        </div>
                        <div
                            class="d-flex justify-start">
                            <v-text-field
                                dense 
                                outlined 
                                v-model="form.year_3_pic_salesperson"
                                label="Salesperson">
                            </v-text-field>
                        </div>
                        <div 
                            class="d-flex justify-end">
                            <v-btn
                                color="primary" 
                                plain
                                @click="()=>{
                                    this.$router.go(-1);
                                }">
                                Cancel
                            </v-btn> 
                            <v-btn 
                                @click="validateInput"
                                color="primary">
                                Confirm
                            </v-btn>
                        </div>
                        </v-container>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessFour from '../../common/ASuccessFour.vue';
import AConfirmation from '../../common/AConfirmation.vue';
import ComponentDateModal from '../../date/ComponentDateModal.vue';
export default {
    components:{
    AConfirmation,
    ComponentDateModal,
    ASuccessFour,
},
    computed: mapState({
    //
    }),
    props:[
        'id'
    ],
    data: () => ({
        isPending:false,
        form:{
            name:null,
            cs_pic:null,
            year_1_payment_date:null,
            year_1_subscription_start_date:null,
            year_1_subscription_end_date:null,
            year_1_pic_salesperson:null,
            year_2_subscription_status:null,
            year_2_payment_date:null,
            year_2_subscription_start_date:null,
            year_2_subscription_end_date:null,
            year_2_pic_salesperson:null,
            year_3_subscription_status:null,
            year_3_payment_date:null,
            year_3_subscription_start_date:null,
            year_3_subscription_end_date:null,
            year_3_pic_salesperson:null,
        },
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
        optionRenewedStatus:[
            'Pending','Rejected','Cancelled','Confirmed','Renewed','Paused','N/A'
        ]
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="update") {
                this.api.isSuccesful = true;
                this.api.success = resp.data;
            }
            if(resp.class==="getSubscriptionHistory") {
                this.form = resp.data;
                this.form.year_1_payment_date = this.form.year_1_payment_date.split(" ")[0];
                this.form.year_1_subscription_start_date = this.form.year_1_subscription_start_date.split(" ")[0];
                this.form.year_1_subscription_end_date = this.form.year_1_subscription_end_date.split(" ")[0];
                this.form.year_2_payment_date = this.form.year_2_payment_date.split(" ")[0];
                this.form.year_2_subscription_start_date = this.form.year_2_subscription_start_date.split(" ")[0];
                this.form.year_2_subscription_end_date = this.form.year_2_subscription_end_date.split(" ")[0];
                this.form.year_3_payment_date = this.form.year_3_payment_date.split(" ")[0];
                this.form.year_3_subscription_start_date = this.form.year_3_subscription_start_date.split(" ")[0];
                this.form.year_3_subscription_end_date = this.form.year_3_subscription_end_date.split(" ")[0];
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompaniesSubscriptionHistoryApi = this.fetchCompaniesSubscriptionHistory();
            this.$api.fetch(fetchCompaniesSubscriptionHistoryApi);
        },
        fetchCompaniesSubscriptionHistory() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription_history/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetchCompaniesDetail() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/companies/'+this.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateSubscriptionHistoryApi = this.updateSubscriptionHistory();
            this.$api.fetch(updateSubscriptionHistoryApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
          
        },
        updateSubscriptionHistory() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription_history/"+this.$router.history.current.params.id;
            let tempForm = this.$_.clone(this.form);
            let keysToExclude  = ['cs_pic','name','id','created_at','updated_at','excel_key'];

            keysToExclude.forEach(key => delete tempForm[key]);
            tempApi.params = tempForm;
            return tempApi;
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        }
    }
}
</script>