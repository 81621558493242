var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ASuccessFour',{attrs:{"api":this.api}}),_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),(_vm.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),(!_vm.api.isLoading && _vm.form.name!=null)?_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"mx-auto",attrs:{"align":"center","justify":"center","cols":"12","sm":"8"}},[_c('v-col',{attrs:{"align":"start","justify":"start"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":_vm.redirectBack}},[_c('v-icon',[_vm._v(" mdi-arrow-left-bold ")])],1)],1),_c('v-card',{staticClass:"pa-3",attrs:{"elevation-1":""}},[_c('v-toolbar',{staticClass:"secondary white--text text-h4 ma-4",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Subscription History ")])],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"d-flex justify-start"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":"","label":"Company name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-start mt-3 "},[_c('h3',[_vm._v(" New Subscription Detail ")])]),_c('div',{staticClass:"d-flex justify-start mb-5 grey--text"},[_c('small',[_vm._v(" **For year 1 subscriptions ")])]),_c('div',{},[_c('ComponentDateModal',{attrs:{"label":"Payment Date","data":_vm.form.year_1_payment_date},on:{"updateDateData":_vm.updateDataPaymentDate}})],1),_c('div',[_c('v-row',[_c('v-col',[_c('ComponentDateModal',{attrs:{"label":"Subscription start date","data":_vm.form.year_1_subscription_start_date},on:{"updateDateData":function (data){
                                            this$1.form.year_1_subscription_start_date = data;
                                        }}})],1),_c('v-col',[_c('ComponentDateModal',{attrs:{"label":"Subscription end date","data":_vm.form.year_1_subscription_end_date},on:{"updateDateData":function (data){
                                                this$1.form.year_1_subscription_end_date = data;
                                            }}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-start"},[_c('v-text-field',{attrs:{"label":"Salesperson","dense":"","outlined":""},model:{value:(_vm.form.year_1_pic_salesperson),callback:function ($$v) {_vm.$set(_vm.form, "year_1_pic_salesperson", $$v)},expression:"form.year_1_pic_salesperson"}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-start mt-3 "},[_c('h3',[_vm._v(" Renewal Year 2 ")])]),_c('div',{staticClass:"d-flex justify-start mb-5 grey--text"},[_c('small',[_vm._v(" **For year 2 subscriptions ")])]),_c('div',{staticClass:"d-flex justify-start"},[_c('v-autocomplete',{attrs:{"label":"Renewal Status","dense":"","outlined":"","items":_vm.optionRenewedStatus},model:{value:(_vm.form.year_2_subscription_status),callback:function ($$v) {_vm.$set(_vm.form, "year_2_subscription_status", $$v)},expression:"form.year_2_subscription_status"}})],1),_c('div',[_c('ComponentDateModal',{attrs:{"label":"Payment Date","data":_vm.form.year_2_payment_date},on:{"updateDateData":function (data){
                                        this$1.form.year_2_payment_date = data;
                                    }}})],1),_c('div',[_c('v-row',[_c('v-col',[_c('ComponentDateModal',{attrs:{"label":"Subscription start date","data":_vm.form.year_2_subscription_start_date},on:{"updateDateData":function (data){
                                            this$1.form.year_2_subscription_start_date = data;
                                        }}})],1),_c('v-col',[_c('ComponentDateModal',{attrs:{"label":"Subscription end date","data":_vm.form.year_2_subscription_end_date},on:{"updateDateData":function (data){
                                                this$1.form.year_2_subscription_end_date = data;
                                            }}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-start"},[_c('v-text-field',{attrs:{"label":"Salesperson","dense":"","outlined":""},model:{value:(_vm.form.year_2_pic_salesperson),callback:function ($$v) {_vm.$set(_vm.form, "year_2_pic_salesperson", $$v)},expression:"form.year_2_pic_salesperson"}})],1)],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-start mt-3 "},[_c('h3',[_vm._v(" Renewal Year 3 ")])]),_c('div',{staticClass:"d-flex justify-start mb-5 grey--text"},[_c('small',[_vm._v(" **For year 3 subscriptions ")])]),_c('div',[_c('v-autocomplete',{attrs:{"label":"Renewal Status","dense":"","outlined":"","items":_vm.optionRenewedStatus},model:{value:(_vm.form.year_3_subscription_status),callback:function ($$v) {_vm.$set(_vm.form, "year_3_subscription_status", $$v)},expression:"form.year_3_subscription_status"}})],1),_c('div',[_c('ComponentDateModal',{attrs:{"label":"Payment date","data":_vm.form.year_3_payment_date},on:{"updateDateData":function (data){
                                this$1.form.year_3_payment_date = data;
                            }}})],1),_c('div',[_c('v-row',[_c('v-col',[_c('ComponentDateModal',{attrs:{"label":"Subscription start date","data":_vm.form.year_3_subscription_start_date},on:{"updateDateData":function (data){
                                        this$1.form.year_3_subscription_start_date = data;
                                    }}})],1),_c('v-col',[_c('ComponentDateModal',{attrs:{"label":"Subscription end date","data":_vm.form.year_3_subscription_end_date},on:{"updateDateData":function (data){
                                        this$1.form.year_3_subscription_end_date = data;
                                    }}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-start"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Salesperson"},model:{value:(_vm.form.year_3_pic_salesperson),callback:function ($$v) {_vm.$set(_vm.form, "year_3_pic_salesperson", $$v)},expression:"form.year_3_pic_salesperson"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                this$1.$router.go(-1);
                            }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.validateInput}},[_vm._v(" Confirm ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }